export default function GasIcon({ ...props }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0.5C1 0.223858 1.22386 0 1.5 0H8.5C8.77614 0 9 0.223858 9 0.5V8H10.5C11.3284 8 12 8.67157 12 9.5V13.5C12 13.7761 12.2239 14 12.5 14H13.5C13.7761 14 14 13.7761 14 13.5V8.41421C14 8.149 13.8946 7.89464 13.7071 7.70711L12.2929 6.29289C12.1054 6.10536 12 5.851 12 5.58579V3.20711C12 3.0745 11.9473 2.94732 11.8536 2.85355L10.3536 1.35355C10.1583 1.15829 10.1583 0.841709 10.3536 0.646447L10.6464 0.353553C10.8417 0.158291 11.1583 0.158291 11.3536 0.353553L14.8536 3.85355C14.9473 3.94732 15 4.0745 15 4.20711V13.5C15 14.3284 14.3284 15 13.5 15H12.5C11.6716 15 11 14.3284 11 13.5V9.5C11 9.22386 10.7761 9 10.5 9H9V13H9.5C9.77614 13 10 13.2239 10 13.5V14.5C10 14.7761 9.77614 15 9.5 15H0.5C0.223858 15 0 14.7761 0 14.5V13.5C0 13.2239 0.223858 13 0.5 13H1V0.5ZM2.5 1C2.22386 1 2 1.22386 2 1.5V4.5C2 4.77614 2.22386 5 2.5 5H7.5C7.77614 5 8 4.77614 8 4.5V1.5C8 1.22386 7.77614 1 7.5 1H2.5Z"
      />
    </svg>
  )
}
